<script setup lang="ts">
import { computed, ref } from 'vue';
import {
  ArrowIcon,
  Direction,
  Avatar,
  SkeletonCircle,
  SkeletonSquare,
  Dropdown,
  SearchIcon,
} from '@wision/ui';
import { useMutation } from '@tanstack/vue-query';
import { withUseQuery, type UserSession } from '@wision/api';
import { toast } from 'vue3-toastify';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const apiClient = useApi();

const props = defineProps({
  minimized: {
    type: Boolean,
    default: false,
  },
});
const session = useSession();
const filterValue = ref<string | null>();
const inputRef = ref<HTMLElement | null>(null);

const currentOrg = computed(() =>
  session.get.value?.organizationAccess?.find(
    (org: UserSession['organizationAccess'][number]) => org.id === session.get.value?.currentOrganization
  )
);

const filteredOrgs = computed(() =>
  session.get.value?.organizationAccess
    ?.filter((org: UserSession['organizationAccess'][number]) => org.id !== session.get.value?.currentOrganization)
    .sort((a: { name: string }, b: { name: string }) => (a.name > b.name ? 1 : -1))
    .filter((org: UserSession['organizationAccess'][number]) =>
      org.name
        ?.toLocaleLowerCase()
        .includes(filterValue.value?.toLocaleLowerCase() ?? '')
    )
);

const updateContextMutation = useMutation(
  (organization: number) =>
    withUseQuery(() => apiClient.user.updateCurrentOrganization(organization)),
  {
    onSuccess: () => {
      location.reload();
    },
    onError: () => {
      toast(t('common.somethingwentwrong'), {
        autoClose: 2000,
        type: 'error',
        theme: 'dark',
      });
    },
  }
);

const handleOnClickOption = (orgId: number) => {
  updateContextMutation.mutate(orgId);
};

const focusInput = () => inputRef.value?.focus();
</script>

<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <div v-if="filteredOrgs && filteredOrgs?.length > 0 || filterValue">
      <Dropdown :on-open="focusInput">
        <template #button="{ isOpen }">
          <div class="cursor-pointer">
            <div
              v-if="currentOrg"
              class="relative flex gap-2 items-center"
              :class="{
                'mr-10': !props.minimized,
              }"
            >
              <div
                class="w-[29px] h-[29px] text-[10px]"
                :class="{
                  'w-[35px] h-[35px]': props.minimized,
                }"
              >
                <Avatar :name="currentOrg.name" />
              </div>
              <p
                v-if="!props.minimized"
                class="truncate text-xs max-w-[160px] text-left"
              >
                {{ currentOrg.name }}
              </p>
            </div>
            <div
              v-if="!props.minimized"
              class="absolute right-4 bottom-0 top-0 flex items-center w-2"
            >
              <ArrowIcon :direction="isOpen ? Direction.Up : Direction.Down" />
            </div>
          </div>
        </template>
        <template #default="{ close }">
          <div class="w-full max-h-[300px] min-w-[250px]">
            <div
              v-if="(session.get.value?.organizationAccess?.length ?? 0) > 4"
              class="p-2 border-b-[1px] border-gray-500"
            >
              <div class="px-1 rounded-lg flex items-center">
                <SearchIcon />
                <input
                  ref="inputRef"
                  v-model="filterValue"
                  placeholder="Search"
                  class="bg-transparent w-full h-full px-4 py-2 cursor-pointer outline-none"
                >
              </div>
            </div>
            <div class="overflow-auto scroll-bar-thin max-h-[238px] my-4">
              <div
                v-for="org in filteredOrgs"
                :key="org.id"
                class="w-full overflow-hidden truncate py-3 px-4 hover:bg-gray-600 cursor-pointer select-none rounded-md"
                @click="
                  () => {
                    close();
                    handleOnClickOption(org.id);
                  }
                "
              >
                <div
                  class="relative flex gap-2 items-center"
                  :class="{
                    'mr-10': !props.minimized,
                  }"
                >
                  <div class="w-8 h-8 text-[10px]">
                    <Avatar :name="org.name" />
                  </div>
                  <p class="truncate text-[12px] max-w-[200px] text-left">
                    {{ org.name }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </template>
      </Dropdown>
    </div>
    <div v-else-if="currentOrg">
      <div>
        <div
          class="relative flex gap-2 items-center"
          :class="{
            'mr-10': !props.minimized,
          }"
        >
          <div
            class="w-[29px] h-[29px] text-[10px]"
            :class="{
              'w-[35px] h-[35px]': props.minimized,
            }"
          >
            <Avatar :name="currentOrg.name" />
          </div>
          <p
            v-if="!props.minimized"
            class="truncate text-xs max-w-[160px] text-left"
          >
            {{ currentOrg.name }}
          </p>
        </div>
      </div>
    </div>
    <div
      v-else
      class="flex items-center justify-center gap-2"
    >
      <SkeletonCircle class="w-8 h-8" />
      <SkeletonSquare class="w-36 h-4" />
    </div>
  </transition>
</template>
