<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { isMobile } from '@/features/navigation/store';
import { useStore } from '@nanostores/vue';
import { dashboardListOpen } from './store';
import AddDashboard from './AddDashboard.vue';
import RenameDashboard from './RenameDashboard.vue';
import { onMounted } from 'vue';
import { withUseQuery } from '@wision/api';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';
import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query';
import {
  ArrowIcon,
  Direction,
  WidgetIcon,
  PlusIcon,
  Dropdown,
  MoreIcon,
  TrashCanIcon,
  PenIcon,
  Modal,
  CustomButton,
} from '@wision/ui';

const { t } = useI18n();
const apiClient = useApi();

const queryClient = useQueryClient();
const $isMobile = useStore(isMobile);
const $dashboardListOpen = useStore(dashboardListOpen);

const pathname = ref('');
const addDashboardModalOpen = ref(false);
const isMounted = ref(false);
const dashboardList = computed(() =>
  data.value?.map((dashboard) => ({
    id: dashboard.id,
    name: dashboard.content?.name ?? '',
  }))
);
const selectedDashboardForNameChange = ref<string | null>(null);
const dashboardIdDelete = ref<string | null>(null);
const containerRef = ref<HTMLElement | null>(null);
const menuOpen = ref(false);

const currentDashboard = computed(() => {
  if (!pathname.value.includes('dashboard')) return 0;

  return pathname.value.split('/').pop();
});

const { data, isLoading } = useQuery(
  [apiClient.dashboard.cacheKeys.dashboards],
  () => withUseQuery(() => apiClient.dashboard.getall(true, true)),
  {
    enabled: isMounted,
  }
);

const deleteDashboardMutation = useMutation(
  (id: string) => withUseQuery(() => apiClient.dashboard.delete(id)),
  {
    onSuccess: (data, id) => {
      queryClient.invalidateQueries({
        queryKey: [apiClient.dashboard.cacheKeys.dashboards],
      });
      if (id === currentDashboard.value) {
        const next = dashboardList.value?.find(
          (dashboard) => dashboard.id !== id
        );
        window.location.href = next ? `/dashboard/${next?.id}` : '/dashboard';
      }
    },
    onError: () =>
      toast(t('toast.invalidcredentials'), {
        autoClose: 2000,
        type: 'error',
        theme: 'dark',
      }),
  }
);

const handleDeleteDashboard = () => {
  if (!dashboardIdDelete.value) return;

  deleteDashboardMutation.mutate(dashboardIdDelete.value);
  dashboardIdDelete.value = null;
};

const getDashboardName = (name: string) =>
  name.includes('nav') ? t(name) : name;

const handleClickDashboardLink = () => dashboardListOpen.set(false);

onMounted(() => {
  isMounted.value = true;
  pathname.value = window.location.pathname;
});

const handleClickWindow = (e: MouseEvent) =>  {
  if (containerRef.value?.contains(e.target as Node) === false &&
    !addDashboardModalOpen.value &&
    !selectedDashboardForNameChange.value &&
    !menuOpen.value) {
    dashboardListOpen.set(false);
  }
};
watch($dashboardListOpen, () => {
  if ($dashboardListOpen) {
    window.addEventListener('click', handleClickWindow);
  } else {
    window.removeEventListener('click', handleClickWindow);
  }
});
</script>

<template>
  <div
    ref="containerRef"
    class="h-full"
  >
    <transition
      enter-active-class="transition-all ease-in-out transform"
      leave-active-class="transition-all ease-in transform"
      enter-from-class="-translate-x-16"
      enter-to-class="translate-x-0"
      leave-to-class="-translate-x-16"
    >
      <div
        v-if="$dashboardListOpen"
        class="h-full"
      >
        <div
          class="absolute z-20 h-full pl-16 md:pl-0 md:z-10 md:relative"
          :class="[{ 'w-full': $isMobile === true }]"
        >
          <div
            class="scroll-bar-thin flex flex-col h-full pt-4 pb-4 overflow-y-auto md:px-4 md:pt-6 dark:text-dark-primary dark:bg-dark-dashboardList md:dark:bg-dark-menu text-bright-primary md:bg-bright-menu bg-bright-dashboardList md:w-60"
          >
            <div class="flex flex-row justify-between w-full md:flex-col">
              <div class="self-center px-5 font-bold text-center">
                {{ t('dashboard.dashboards') }}
              </div>
              <div
                class="flex items-center p-2 px-10 text-white cursor-pointer md:mt-1 md:px-2"
                @click="addDashboardModalOpen = !addDashboardModalOpen"
              >
                <PlusIcon />
                <span class="hidden ml-2 md:inline-flex">
                  {{ $t('common.add') }}
                </span>
              </div>
            </div>

            <hr
              class="border-gray-700 md:border-gray-500 md:my-8"
              :class="[{ 'mt-4': $isMobile === true }]"
            >
            <ul
              v-if="!isLoading"
              class="divide-y divide-gray-700 md:space-y-2 md:divide-y-0"
            >
              <li
                v-for="dashboard in dashboardList"
                :key="dashboard.id"
                :class="{
                  'bg-gray-800': dashboard.id === currentDashboard,
                }"
                class="px-2 py-2 md:py-0 md:px-0 md:rounded hover:bg-gray-800 active:bg-gray-800"
              >
                <div class="flex items-center group">
                  <NuxtLink
                    :href="`/dashboard/${dashboard.id}`"
                    class="flex items-center w-full p-3 overflow-hidden md:p-2 dark:text-dark-primary text-bright-primary"
                    @click="handleClickDashboardLink"
                  >
                    <WidgetIcon />

                    <div class="flex justify-between flex-1">
                      <span
                        class="overflow-hidden text-15.8px w-60 md:w-auto md:text-base whitespace-nowrap overflow-ellipsis"
                      >
                        {{ dashboard.name.includes('nav') ? $t(dashboard.name) : dashboard.name }}
                      </span>
                      <ArrowIcon
                        v-if="$isMobile"
                        :direction="Direction.Right"
                      />
                    </div>
                  </NuxtLink>
                  <Dropdown
                    class="text-gray-600 hover:text-white pr-2 hidden md:block"
                    :on-open="() => menuOpen = true"
                    :on-close="() => menuOpen = false"
                  >
                    <template #button>
                      <MoreIcon class="cursor-pointer" />
                    </template>
                    <template #default="{ close }">
                      <div class="w-48 p-2">
                        <button
                          class="flex items-center w-full p-3 hover:bg-gray-600 rounded-md"
                          @click="() => (dashboardIdDelete = dashboard.id)"
                        >
                          <TrashCanIcon />
                          <span class="ml-2 text-sm">{{
                            t('common.remove')
                          }}</span>
                        </button>
                        <button
                          class="flex items-center w-full p-3 hover:bg-gray-600 rounded-md"
                          @click="selectedDashboardForNameChange = dashboard.id; close()"
                        >
                          <PenIcon />
                          <span class="ml-2 text-sm">{{
                            t('common.rename')
                          }}</span>
                        </button>
                      </div>
                    </template>
                  </Dropdown>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div
          class="dark:text-dark-primary dark:bg-dark-menu text-bright-primary bg-bright-menu"
        >
          <AddDashboard
            :show-modal="addDashboardModalOpen"
            @close="addDashboardModalOpen = false"
          />
        </div>
        <RenameDashboard
          :dashboard-id="selectedDashboardForNameChange ?? undefined"
          :on-close="() => (selectedDashboardForNameChange = null)"
        />
      </div>
    </transition>
    <Modal
      class="text-white"
      :show-modal="!!dashboardIdDelete"
      @close="() => (dashboardIdDelete = null)"
    >
      <div class="w-[13rem] m-auto">
        <div>
          {{
            t('common.removeconfirm', [
              getDashboardName(dashboardList?.find((dashboard) => dashboard.id === dashboardIdDelete)?.name ?? '')
            ])
          }}
        </div>
        <div class="flex gap-2 mt-2">
          <CustomButton @click="handleDeleteDashboard">
            {{ t('yes') }}
          </CustomButton>
          <CustomButton @click="dashboardIdDelete = null">
            {{ t('no') }}
          </CustomButton>
        </div>
      </div>
    </Modal>
  </div>
</template>
