<template>
  <svg
    class="fill-current"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-19.000000, -215.000000)">
        <g transform="translate(19.000000, 215.000000)">
          <polygon points="0 0 24 0 24 24 0 24"></polygon>
          <path
            d="M5,15 L3,15 L3,19 C3,20.1 3.9,21 5,21 L9,21 L9,19 L5,19 L5,15 Z M5,5 L9,5 L9,3 L5,3 C3.9,3 3,3.9 3,5 L3,9 L5,9 L5,5 Z M19,3 L15,3 L15,5 L19,5 L19,9 L21,9 L21,5 C21,3.9 20.1,3 19,3 Z M19,19 L15,19 L15,21 L19,21 C20.1,21 21,20.1 21,19 L21,15 L19,15 L19,19 Z M12,9 C10.34,9 9,10.34 9,12 C9,13.66 10.34,15 12,15 C13.66,15 15,13.66 15,12 C15,10.34 13.66,9 12,9 Z"
            id="Shape"
            fill="currentColor"
            fill-rule="nonzero"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>
