<script setup lang="ts">
import { onMounted, ref, watch, nextTick } from 'vue';
import CustomButton from '../components/CustomButton.vue';
import { useI18n } from 'vue-i18n';
import CloseIcon from '../icons/CloseIcon.vue';

const { t } = useI18n();
const emit = defineEmits(['close', 'confirm']);
const props = defineProps({
  titleText: {
    type: String,
    required: true,
  },
  maxLength: {
    type: Number,
    required: true,
  },
  confirmText: {
    type: String,
    required: true,
  },
  showModal: {
    type: Boolean,
    required: true
  }
});

const nameInputRef = ref();
const value = ref<string>();

onMounted(() => {
  nameInputRef.value?.focus();
});

const confirm = () => {
  if (value.value?.length) {
    emit('confirm', value.value);
    emit('close');
    value.value = '';
  }
};

const close = () => {
  value.value = '';
  emit('close');
};

watch(() => props.showModal, async () => {
  if (props.showModal) {
    await nextTick();
    nameInputRef.value?.focus();
  }
});
</script>

<template>
  <transition
    appear
    enter-active-class="transition-opacity ease-out"
    leave-active-class="transition-opacity ease-in"
    enter-to-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      v-if="props.showModal"
      :class="`
        fixed
        top-0
        left-0
        z-50
        flex
        items-center
        justify-center
        w-full
        h-full
        bg-black
        bg-opacity-30
        backdrop-blur
        text-white
      `"
    >
      <div
        class="flex w-full h-full p-2 md:p-48"
        @click.self="close"
      >
        <div
          class="flex flex-col flex-1 max-w-lg mx-auto my-auto rounded shadow bg-dark-widget"
        >
          <div
            class="flex items-center justify-between p-4 border-b border-gray-700"
          >
            <h2 class="mb-0 text-lg font-bold">
              {{ titleText }}
            </h2>

            <button @click="close">
              <CloseIcon />
            </button>
          </div>

          <div class="flex flex-col items-center content-center flex-1">
            <h3 class="mt-4 text-lg">
              {{ t('common.name') }}
            </h3>
            <div class="flex-row m-4">
              <input
                ref="nameInputRef"
                v-model="value"
                class="pl-4 pr-2 mb-1 leading-8 border border-gray-200 rounded bg-dark-widget"
                :maxlength="maxLength"
                type="text"
                @keyup.enter="confirm"
              >
              <p
                v-if="maxLength"
                class="text-xs italic text-right text-gray-400"
              >
                {{ t('common.max') + ':' + maxLength }}
              </p>
            </div>

            <div class="flex-row px-3 mt-8 mb-4 ml-auto space-x-3">
              <CustomButton
                :types="['auto-width', 'border']"
                @click="close"
              >
                {{ t('common.cancel') }}
              </CustomButton>
              <CustomButton
                :types="['auto-width', 'border']"
                @click="confirm"
              >
                {{ props.confirmText }}
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
