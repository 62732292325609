<script setup lang="ts">
import { ref } from 'vue';
import LanguageSettings from './LanguageSettings.vue';

defineEmits(['close']);

const activeTab = ref(1);

</script>

<template>
  <div
    class="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-30 backdrop-blur"
    @click.self="$emit('close')"
  >
    <div
      class="flex flex-col flex-1 max-w-3xl mx-auto my-auto rounded-lg shadow bg-dark-widget h-80vh md:h-96"
    >
      <div
        class="flex items-center justify-between p-4 border-b border-gray-700"
      >
        <svg
          class="text-gray-500 fill-current"
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          width="24"
        >
          <path
            d="M0 0h24v24H0V0z"
            fill="none"
          />
          <path
            d="M19.43 12.98c.04-.32.07-.64.07-.98 0-.34-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46a.5.5 0 00-.61-.22l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65A.488.488 0 0014 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1a.566.566 0 00-.18-.03c-.17 0-.34.09-.43.25l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98 0 .33.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46a.5.5 0 00.61.22l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.06.02.12.03.18.03.17 0 .34-.09.43-.25l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zm-1.98-1.71c.04.31.05.52.05.73 0 .21-.02.43-.05.73l-.14 1.13.89.7 1.08.84-.7 1.21-1.27-.51-1.04-.42-.9.68c-.43.32-.84.56-1.25.73l-1.06.43-.16 1.13-.2 1.35h-1.4l-.19-1.35-.16-1.13-1.06-.43c-.43-.18-.83-.41-1.23-.71l-.91-.7-1.06.43-1.27.51-.7-1.21 1.08-.84.89-.7-.14-1.13c-.03-.31-.05-.54-.05-.74s.02-.43.05-.73l.14-1.13-.89-.7-1.08-.84.7-1.21 1.27.51 1.04.42.9-.68c.43-.32.84-.56 1.25-.73l1.06-.43.16-1.13.2-1.35h1.39l.19 1.35.16 1.13 1.06.43c.43.18.83.41 1.23.71l.91.7 1.06-.43 1.27-.51.7 1.21-1.07.85-.89.7.14 1.13zM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"
          />
        </svg>

        <h2 class="mb-0 text-xl font-bold">
          {{ $t('modal.settings') }}
        </h2>

        <button @click="$emit('close')">
          <svg
            class="fill-current"
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            width="24"
          >
            <path
              d="M0 0h24v24H0V0z"
              fill="none"
            />
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"
            />
          </svg>
        </button>
      </div>
      <div class="flex flex-col flex-1 md:flex-row">
        <div
          class="w-full border-b border-gray-700 min-w-max md:w-1/4 md:border-r"
        >
          <ul>
            <li
              class="flex justify-between p-3 cursor-pointer hover:bg-gray-500"
              :class="{ 'bg-gray-700': activeTab == 1 }"
              @click="activeTab = 1"
            >
              {{ $t('modal.language') }}

              <svg
                class="fill-current"
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                width="24"
              >
                <path
                  d="M0 0h24v24H0V0z"
                  fill="none"
                />
                <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z" />
              </svg>
            </li>
          </ul>
        </div>
        <div class="flex-1">
          <LanguageSettings v-if="activeTab == 1" />
        </div>
      </div>
    </div>
  </div>
</template>
