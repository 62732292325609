<template>
  <svg
    width="21px"
    height="22px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-20.000000, -264.000000)">
        <g transform="translate(20.000000, 264.000000)">
          <rect x="0" y="0" width="24" height="24"></rect>
          <g
            transform="translate(3.000000, 1.000000)"
            fill="#FFFFFF"
            fill-rule="nonzero"
          >
            <path
              d="M2,4 L4,4 L4,7 L14,7 L14,4 L16,4 L16,9 L18,9 L18,4 C18,2.9 17.1,2 16,2 L11.82,2 C11.4,0.84 10.3,0 9,0 C7.7,0 6.6,0.84 6.18,2 L2,2 C0.9,2 0,2.9 0,4 L0,18 C0,19.1 0.9,20 2,20 L8,20 L8,18 L2,18 L2,4 Z M9,2 C9.55,2 10,2.45 10,3 C10,3.55 9.55,4 9,4 C8.45,4 8,3.55 8,3 C8,2.45 8.45,2 9,2 Z"
              id="Shape"
            ></path>
            <polygon
              id="Path"
              points="18 10.5 12.51 16 9.5 13 8 14.5 12.51 19 19.5 12"
            ></polygon>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
