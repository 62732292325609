<template>
  <svg width="19" height="19" xmlns="http://www.w3.org/2000/svg">
    <g stroke="#FFF" fill="none" fill-rule="evenodd">
      <rect x=".5" y=".5" width="7" height="7" rx="1" />
      <rect x=".5" y="11.5" width="7" height="7" rx="1" />
      <rect x="11.5" y=".5" width="7" height="7" rx="1" />
      <rect x="11.5" y="11.5" width="7" height="7" rx="1" />
    </g>
  </svg>
</template>
