<!-- eslint-disable max-len -->
<template>
  <svg
    class="flex-shrink-0 w-4 h-4 mr-2 fill-current"
    width="14"
    height="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <path d="M0 0h14v14H0z" />
      <path
        d="M10.778 3.222v1.222H8.333V3.222h2.445m-6.111 0V6.89H2.222V3.222h2.445m6.11 4.89v3.666H8.334V8.11h2.445m-6.111 2.445v1.222H2.222v-1.222h2.445M12 2H7.111v3.667H12V2zM5.889 2H1v6.111h4.889V2zM12 6.889H7.111V13H12V6.889zM5.889 9.333H1V13h4.889V9.333z"
        fill="#FFF"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>
