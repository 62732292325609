<script setup lang="ts">
import { ref, nextTick, watch, computed } from 'vue';
import {
  CustomButton,
  Select,
  LoadingSpinner,
  ModalWithHeader,
} from '@wision/ui';
import { useI18n } from 'vue-i18n';
import { useQuery, useMutation } from '@tanstack/vue-query';
import { withUseQuery } from '@wision/api';
import { toast } from 'vue3-toastify';

const maxLength = 40;

const { t } = useI18n();
const apiClient = useApi();

const props = defineProps({
  showModal: {
    type: Boolean,
    required: true,
  },
});

const { data, isLoading } = useQuery(['templates'], () =>
  withUseQuery(apiClient.dashboard.getTemplates)
);

defineEmits(['close']);

const nameInputRef = ref();
const selectedTemplate = ref('blank');
const value = ref<string>();
const isAddingDashboard = ref(false);

const templates = computed(() => [
  ...(data.value?.map((template) => ({
    value: template.id,
    label: t(template.name),
  })) ?? []),
]);

const addDashboardMutation = useMutation(
  ['templates'],
  ({ name, templateId }: { name: string; templateId?: string }) =>
    withUseQuery(() => apiClient.dashboard.create(name, templateId, true)),
  {
    onSuccess: (res) => (window.location.href = `/dashboard/${res.id}`),
    onError: () => {
      isAddingDashboard.value = false;
      toast(t('admin.userprofile.add.failed'), {
        autoClose: 2000,
        type: 'error',
        theme: 'dark',
      });
    },
  }
);

watch(
  () => props.showModal,
  async () => {
    if (!props.showModal) return;

    await nextTick();
    nameInputRef.value?.focus();
  }
);

const confirm = () => {
  if (value.value) {
    isAddingDashboard.value = true;
    addDashboardMutation.mutate({
      name: value.value,
      templateId: selectedTemplate.value,
    });
  }
};
</script>

<template>
  <ModalWithHeader
    :label="t('dashboard.createdashboard')"
    :show="showModal"
    @close="$emit('close')"
  >
    <div
      v-if="isLoading || isAddingDashboard"
      class="w-20 m-auto flex h-full"
    >
      <LoadingSpinner />
    </div>
    <div
      v-else
      class="flex flex-col items-center content-center flex-1"
    >
      <h3 class="mt-4 text-lg">
        {{ t('common.name') }}
      </h3>
      <div class="flex-row m-4">
        <input
          ref="nameInputRef"
          v-model="value"
          class="pl-4 pr-2 mb-1 leading-8 border border-gray-200 rounded bg-dark-widget"
          :maxlength="maxLength"
          type="text"
          @keyup.enter="confirm"
        >
        <p
          v-if="maxLength"
          class="text-xs italic text-right text-gray-400"
        >
          {{ t('common.max') + ':' + maxLength }}
        </p>
      </div>
      <div class="flex flex-col items-start">
        <p>{{ t('dashboard.template') }}</p>
        <Select
          v-model="selectedTemplate"
          :options="templates"
          :place-holder="'Select template'"
        />
      </div>

      <div class="flex-row px-3 mt-8 mb-4 ml-auto space-x-3">
        <CustomButton
          :types="['auto-width', 'border']"
          @click="$emit('close')"
        >
          {{ t('common.cancel') }}
        </CustomButton>
        <CustomButton
          :types="['auto-width', 'border']"
          @click="confirm"
        >
          {{ t('dashboard.createdashboard') }}
        </CustomButton>
      </div>
    </div>
  </ModalWithHeader>
</template>
